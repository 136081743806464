import {
  Container,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Layout from '@/components/Layout';

const useStyles = makeStyles(() => ({
  root: {
    margin: '50px auto',
    padding: 20,
    textAlign: 'justify',
  },
}));

const Faq = () => {
  const classes = useStyles();
  return (
    <Layout title="Kingdom Guilds - FAQ">
      <Container maxWidth="md">
        <Paper className={classes.root}>
          <h2>What makes Kingdom Guilds different from other NFT games?</h2>
          <p>
            We played many of the NFT games released from last year until today,
            and on some we made money, on other we lost money. But, mostly when
            losing money, we learn what these games make right, what could be
            different, and Kingdom Guilds is our try to put all these learning
            into a new game.
          </p>
          <p>
            So, you will see mechanics from other games here but with our own
            touch, and you will see that we tried to make a full game, where you
            will have many options on gameplay.
          </p>
          <p>We can list some of these mechanics, and the reason we adopt:</p>
          <ul>
            <li>
              <b>No gas-fee:</b> it not only really annoying have to approve
              every action on the game on metamask, as it makes the game
              development much more complex, not to mention that any problem on
              blockchain makes the game unplayable.
            </li>
            <li>
              <b>BNB token:</b> our main token will be something different from
              what players may think, it will not have any use in game, we will
              explain when we talk about economics of the game, but, for
              example, to buy or sell something on the market, players will use
              BNB, that would be previously deposited in-game.
            </li>
            <li>
              <b>Internal market:</b> player will be able to buy/sell everything
              on game market, the idea is for us have an initial sale of
              packages, and then let the players earn and trade.
            </li>
            <li>
              <b>Auctions:</b> items will be auctioned on market, the seller
              will say the minimum bid and how long the auction will last
              (minimum one hour), and everytime someone bid it will last at
              least more 30 minutes, so, bots will have no use here.
            </li>
            <li>
              <b>Fee on resource tokens contract:</b> we will put a small fee on
              resource tokens contract that will work as an auto-liquidity, we
              really don&apos;t know the &quot;auto&quot; part yet, but the idea
              is that in every trade, a small portion will be added to the
              liquidity pool, this will keep the liquidity pool growing, will
              avoid bot trades and will increase token price as a side effect.
            </li>
          </ul>

          <h2>What about economics?</h2>
          <p>
            As we said before, we will use BNB for market, but we will have our
            token KGT and other five resource tokens for gold, crop, fish, ore
            and wood, you can think of them as rewards tokens too, but
            everything on the game will use these resources, so it will be much
            more than just rewards.
          </p>
          <p>
            So, why not use KGT token on market, or even gold token? To answer
            this, we will have to ask you: what the team makes with the tokens
            that they earn on fees on their games? They sell! Well, game
            development it is not charity, but even it was a charity game, it
            still needs money to function. It has some advantages to use a
            custom token, but we believe that this will be good for the project,
            and specially for market, using BNB has the advantage of been a
            stable coin, so if you put something on market today selling for
            $100 and it take a while to be sold, it will be sold for something
            near $100.
          </p>
          <p>
            But them, what about KGT token? It will be a very special token that
            will function as some kind of stake, where players will have shares
            on all game fees based on guild rarity and quantity of tokens they
            hold, so, players will receive BNB in-game every day just for
            holding KGT tokens
          </p>
          <p>
            Similar with this approach, players that have a guild with epic
            rarity or better will have a share on the fees too, this is to
            stimulate players to upgrade their guild (Yes, there will be rarity
            upgrade mechanic!).
          </p>
          <p>
            And, it is not all, players that own a kingdom, also will have a
            share on fees of the guild on their kingdoms (Kingdom will not be
            available at start of the game, we will bring on a future version)
          </p>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Entity</TableCell>
                  <TableCell>Percent</TableCell>
                </TableRow>
              </TableHead>
              <TableRow>
                <TableCell>Game investment</TableCell>
                <TableCell>50-55%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Team</TableCell>
                <TableCell>25%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>KGT holders</TableCell>
                <TableCell>10%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Guilds epic & legendary</TableCell>
                <TableCell>5%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Guilds mythic & divine</TableCell>
                <TableCell>5%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Kingdom owner</TableCell>
                <TableCell>0-5%</TableCell>
              </TableRow>
            </Table>
          </TableContainer>
          <p>
            <b>Game investment:</b> we say game investment, because it will not
            only be used to pay for costs, it will be used to anything that make
            the game better like: to improve the game, to make a reserve to keep
            the game running for months, to marketing to attract new players, or
            even to buyback or add liquidity to resource tokens
          </p>
          <p>
            <b>Team:</b> this will be distributed to the team for them to use as
            they want
          </p>
          <p>
            <b>KGT holders:</b> we will distribute part of every fee to every
            player with a guild with rarity uncommon or better, every rarity
            will have a weight, even with the same amount of KGT tokens, a
            player with a better guild rarity will receive more.
          </p>
          <p>
            <b>Guilds with epic or legendary rarity:</b> every player that have
            a guild with rarity epic or legendary will be able to receive
          </p>
          <p>
            <b>Guilds with mythic or divine rarity:</b> same as before, every
            player that have a guild with rarity mythic or divine will be able
            to receive, they have the same percent as epic/legendary, but here
            will be many less players, so each player will receive more
          </p>
          <p>
            <b>Kingdom owner:</b> kingdom owner will receive for every fee of
            guilds on their kingdom, the percentage will depend on kingdom
            rarity, and what is left goes to game investment. (Please note that
            kingdom will not be available on game launch)
          </p>
          <p>
            The main idea from this economics, is to allow the team to spend
            whatever they need without any impact on the project tokens and at
            same time give many investment options to players that believes on
            the project. The market fee will be the main income source for the
            game, because you guys will see, everything will be designed to
            encourage trades on market, it will not be just a &quot;buy an egg
            to start the game, then buy another egg&quot;.
          </p>

          <h2>How many guilds can we have?</h2>
          <p>
            You can have only one active guild, but inactive you can have as
            many as you want as will need to get many of them for rarity
            upgrade, there will be ways to acquire guilds inside game
          </p>
          <h2>Can we have multi-accounts?</h2>
          <p>
            The short answer is no, for a long answer: we don&apos;t have time
            or staff members available to deal with this issue, but eventually
            we will have! Having said that, we designed the game in a way that
            it will be much better invest on only one account, than split the
            money in many accounts.
          </p>
          <p>
            How do we encourage players to upgrade rarity from their guild?
            That&apos;s a question that we always ask, and we thought of the
            following:
          </p>
          <ul>
            <li>The market fee will be greatly reduced with better rarities</li>
            <li>
              There will be many features in-game that will require a specific
              rarity
            </li>
            <li>
              With better rarities, players will be able to unlock more slots on
              buildings (i.e., more farming spots to produce crop)
            </li>
            <li>
              To upgrade rarities player will need to have a guild of equivalent
              rarity (except for guild rarity upgrade)
            </li>
            <li>
              Players with better rarities will have a share on the fees just
              for own the guild
            </li>
            <li>
              Players with better rarities will have a better weight on KGT
              token fee share
            </li>
          </ul>
          <p>
            With that, we believe that will be much better have a rare guild
            than multiple common guilds, and at same time, we give players that
            want to enter with the very minimum, ways to earn and upgrade their
            guilds. But we will continue to think how this could be better, so
            you guys can expect that guild rarity will always influence anything
            new on the game.
          </p>
          <h2>How upgrade rarity system will work?</h2>
          <p>
            On Kingdom Guilds, we have the following rarities: common, uncommon,
            rare, epic, legendary, mythic and divine. There will be buildings
            that will allow to upgrade rarities of the game NFTs, like, for
            heroes, you will have to go to Academy building. The only NFT that
            will not have a rarity upgrade system by design, will be Kingdom.
          </p>
          <p>
            The full recipe will vary, maybe you will need some material that is
            craftable, maybe you will need a rare drop from a boss or a reward
            from an event, but the main part will be 2 NFTs of same rarity and 2
            NFTs from previous rarity, so: 2 rare + 2 uncomon = 1 epic (except
            for common to uncommon, that will be 4 common = 1 uncommon).
          </p>

          <h2>How gameplay will work?</h2>
          <p>
            Kingdom Guilds will have 5 resources: gold, crop, fish, ore and
            wood. Heroes will be responsible to get gold on dungeons. For the
            other four, players will have to use specific tool for each one.
            Other important stats is the player stamina, because each action on
            game will consume stamina.
          </p>
          <ul>
            <li>
              <b>Gold:</b> will be used mostly for upgrades and buy things
              in-game
            </li>
            <li>
              <b>Crop:</b> will be used to make beer to give for your heroes, as
              they have a daily consumption of beer, and they will get angry
              without it.
            </li>
            <li>
              <b>Fish:</b> will be used to make dishes on kitchen to recover
              stamina.
            </li>
            <li>
              <b>Ore:</b> will be used to upgrade buildings, forge weapons &
              equipments and craft things.
            </li>
            <li>
              <b>Wood:</b> will be used to upgrade buildings, forge equipments &
              equipments and craft things.
            </li>
          </ul>
          <p>
            Of course this is just the initial idea for each token, as game
            evolves, more and more uses for each will be added.
          </p>

          <h2>What about PVP?</h2>
          <p>
            Our hero battle will be automatic turn based battle, and there will
            be many factors to influence the battle, heroes will have classes
            and sub-classes, and after they end leveling subclass, they can be
            ascended for a ultimate version of that hero.
          </p>
          <p>
            Heroes will have weapons and equipment that will provide stats to
            fortify your hero, not only that, they will have skills, that will
            have many options, could be a critical chance buff, or a behavior
            change like &quot;attack the last hero&quot;.
          </p>
          <p>
            As with equips, heroes will not come with skills, they will have to
            learn, first you will have to obtain a very rare tome of the desired
            skill, then you will have to go to academy building of your guild,
            and teach the skill to the chosen hero, of course, you can buy the
            tome from other players on market too!
          </p>
          <p>
            With all that in mind, we will have a season ranked arena where you
            will send your best heroes to battle with other player, all of them
            looking for valuable rewards on the end of season!
          </p>

          <h2>How do we deposit or withdraw tokens in-game?</h2>
          <p>
            It will be quite simple, on your account page, there will be buttons
            to deposit and to withdraw, then you choose what token and quantity,
            and that is it. (Do not try to send tokens direct for any contract,
            you will lose yours token, do it through the game account page)
          </p>

          <h2>Transfers is possible?</h2>
          <p>
            No. Transfer will not be possible, not now, not later. Just make
            only one account and focus on that.
          </p>

          <h2>There will be a testing phase?</h2>
          <p>
            Yes, there will be a closed testing phase, but we are not there yet,
            but as soon we have a playable version for testing, we will invite
            players to test before launch.
          </p>
          <p />

          <h2>How pre-sale will work?</h2>
          <p>
            We will have packages sales, there will be packages of guild+heroes,
            tools and resources. We will put packages on various sizes so all
            players can buy.
          </p>
          <p>
            Packages will only be available after testing phase is closed to
            finish, so we will have a better estimative of when the launch will
            occour, and players will know better what they will pay for.
          </p>
          <p>
            We will put packages for about 50,000 players, so it will not be a
            pre-sale to be sold out in seconds, this packages will be available
            after game launch until sold out.
          </p>
          <p>
            Guild and tools package will be used to game development and
            resource packages will be used to add liquidity to the resource
            tokens on pancakeswap.
          </p>
          <p>
            This comes from our plan to give the game a start, and after that we
            will not interfere anymore, unless things go wrong. So, after these
            packages sold out, new players will have to buy guilds, heroes,
            tools from the current players, or buy resources to build or craft.
          </p>
          <p>
            There will be no divine guild, hero or tool package, cause we think
            that it is better not to rush the endgame, this will make rarity be
            very valuable, cause it will be costly and time consuming.
          </p>
          <p>
            We will put all packages on pre-sale to be sold, and until launch
            all packages will have a good discount, and after launch it will be
            full price.
          </p>
          <p>
            There will be rules to buy packages, each package will be limited to
            one buy per account, at least until some time after launch, so each
            player can buy multiple different packages, but only one of each
            kind. The packages can be sold on market, so players can buy to
            resell after sold out. And probably will have more rules, that will
            be anounced when pre-sales started.
          </p>
          <p>
            There will be packages reserved for team, partner, advisors, air
            drop and testers. We don&apos;t know all the details yet, so we will
            update players when time comes, but specially for testers we want to
            encourage players to test and give good feedbacks with rewards on
            game launch.
          </p>

          <h2>What about a roadmap? All this features will come at launch?</h2>
          <p>
            No, we have only two core team members, which only one has
            development skills, so if we want to deliver all things at once it
            will take too much time.
          </p>
          <p>
            Our plan is give players as much as we can on launch, giving a
            complete experience and to use the testing phase as a show-off of
            what the game can be, so we can launch a pre-sale after tests and
            raise funds to increase our staff members and deliver all things as
            soon as possible.
          </p>
          <p>
            Our first priority will be hiring a web design to make both website
            and game interface, cause for the initial testing, we will focus on
            development of game mechanics and will not care about design.
          </p>
          <p>
            During the test phase there will be fixes and more features
            available with time, and we plan to end test phase with a new design
            so we launch a not only working game, but a game with good design
            and user experience.
          </p>
          <p>
            So, without further delay, this is what you guys can expect on game
            launch, it will be focused on building and upgrading the guild:
          </p>
          <ul>
            <li>Guild, hero and tools NFTs</li>
            <li>Rarity upgrade system</li>
            <li>Market</li>
            <li>10+ buildings like farm or kitchen</li>
            <li>10+ guild skills like mining skill or alchemy skill</li>
            <li>Level up guild skills to unlock features</li>
            <li>Initial recipes, like roasted fish or beer</li>
            <li>Use heroes and tools to obtain resources</li>
            <li>Daily and weekly tasks</li>
            <li>Ways to obtain guild and hero fragments</li>
            <li>Craft of tools</li>
          </ul>
          <p>
            And the next big release, will be focused on the turn based battle
            system using your heroes:
          </p>
          <ul>
            <li>Story mode</li>
            <li>Ranked PvP</li>
            <li>Hero equipments</li>
            <li>Tomes of skills</li>
          </ul>
          <p>
            And after that, will come kingdom mechanics, as you can see, we have
            big plans for this game, and we will do our best to make this a long
            time project that will become a reference of play to earn games on
            gaming community.
          </p>
          <p>
            Having said that, we want to make it clear that all things said here
            can change, we will always act on behalf of what is good for the
            long term of the game. If in a few months we need to ajust something
            to make the game better or we need to prioritize another thing that
            was not planned, we will, we can&apos;t be hostage of planning, but
            we will try our best to make good plans and to stick with them.
          </p>

          <h2>What can we expect about kingdoms?</h2>
          <p>
            Just like hero battle system will be a game change release, you guys
            can expect the same of kingdoms. Just to give a few spoiler, we are
            planning features that will make kingdoms specialized, it will give
            both buffs as debuffs. It will have all rarities as others NFTs,
            that will impact on the buffs, kingdom size, and other details.
          </p>
          <p>
            For all effects, you can think a kingdom as an aliance, a clan, a
            guild or something like that where players join together with a
            common goal, this kind a thing is present on many games, so you can
            expect features to improve this social part of the game.
          </p>
          <p>
            Of course there will be a few sources of income for kingdom owners,
            after all, this is a play to earn game. They will specify a kingdom
            tax that guilds will have to pay weekly to become part of the
            kingdom, and as already explained, they will have a share on all
            income from the game based on its rarity.
          </p>
          <p>
            On this release, there will be a public kingdom tax too, all guilds
            will have to pay a weekly tax to play the game, the difference is
            that on NFT kingdom, this tax will be defined by the owner and on
            public, there will be a basic tax for all, you can think of this tax
            as a weekly contract that some P2E games have, but in this case who
            will receive will be the owner of the kingdom, and for the public,
            this will go to the game development fund.
          </p>
        </Paper>
      </Container>
    </Layout>
  );
};

export default Faq;
