import { Theme, Stack, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AewsomeButton from '../AewsomeButton';

const logoImage = require('@/images/logo-lg.png').default;

const bgImageSM = require('../../images/bg-sm.jpg').default;
const bgImageLG = require('../../images/bg-lg.jpg').default;
const bgImageXL = require('../../images/bg-xl.jpg').default;
const bgImageXXL = require('../../images/bg-xxl.jpg').default;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: '100vw',
    height: 'calc(100vh - 64px)',
    backgroundImage: `url(${bgImageLG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('lg')]: {
      backgroundImage: `url(${bgImageSM})`,
    },
    [theme.breakpoints.up('xl')]: {
      backgroundImage: `url(${bgImageXL})`,
    },
    [theme.breakpoints.up(2000)]: {
      backgroundImage: `url(${bgImageXXL})`,
    },
    '& h2': {
      textShadow:
        '2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000',
      color: '#fc0',
      fontSize: 36,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
  },
  logobox: {
    marginTop: '25vh',
    '& img': {
      maxWidth: '100%',
    },
  },
}));

export default function HomeSplashScreen() {
  const classes = useStyles();
  return (
    <Stack className={classes.root} direction="column" textAlign="center">
      <Box className={classes.logobox}>
        {logoImage && <img src={logoImage} alt="Kingdom Guilds" />}
      </Box>
      <h2>Play, have fun and why not earn too?</h2>
      <Box>
        <AewsomeButton to="/play/guild">PLAY</AewsomeButton>
      </Box>
    </Stack>
  );
}
