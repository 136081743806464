import React from 'react';

interface GameStatePlayer {
  id: string;
}
interface GameStateAvailableGuild {
  id: string;
  rarity: string;
  slots: number;
}
type GameStateActiveGuild = GameStateAvailableGuild;
interface GameStateAvailableHero {
  id: string;
  code: string;
  totalBeer: number;
  type: string;
  rarity: string;
  class: string;
}
interface GameStateActiveHero extends GameStateAvailableHero {
  needBeer: boolean;
}
interface GameStateItems {
  beers: number;
  reviveScrolls: number;
  bossTickets: number;
  heroFragments: number;
  guildFragments: number;
}
interface GameStateCurrentDungeon {
  status: string;
}
interface GameStateCurrentDaily {
  status: string;
}
interface GameStateYesterdayDaily {
  status: string;
}

interface GameState {
  player: GameStatePlayer;
  activeGuild: GameStateActiveGuild;
  availableGuilds: Array<GameStateAvailableGuild>;
  activeHeroes: Array<GameStateActiveHero>;
  availableHeroes: Array<GameStateAvailableHero>;
  gold: number;
  kggTokens: number;
  items: GameStateItems;
  currentDungeon: GameStateCurrentDungeon;
  currentDaily: GameStateCurrentDaily;
  yesterdayDaily: GameStateYesterdayDaily;
}

const GameStateContext: any = React.createContext(null);

export function GameProvider({ children }) {
  const [status, setStatus] = React.useState('loading');
  // const [token, setToken] = React.useState('');
  const [player, setPlayer] = React.useState<GameStatePlayer>();
  const [activeGuild, setActiveGuild] = React.useState<GameStateActiveGuild>();
  const [availableGuilds, setAvailableGuilds] = React.useState<
    Array<GameStateAvailableGuild>
  >([]);
  const [activeHeroes, setActiveHeroes] = React.useState<
    Array<GameStateActiveHero>
  >([]);
  const [availableHeroes, setAvailableHeroes] = React.useState<
    Array<GameStateAvailableHero>
  >([]);
  const [kgTokens, setKgTokens] = React.useState(0);
  const [gold, setGold] = React.useState(100);
  const [beers, setBeers] = React.useState(0);
  const [reviveScrolls, setReviveScrolls] = React.useState(0);
  const [bossTickets, setBossTickets] = React.useState(0);
  const [heroFragments, setHeroFragments] = React.useState(0);
  const [guildFragments, setGuildFragments] = React.useState(0);

  const [currentDungeon, setCurrentDungeon] =
    React.useState<GameStateCurrentDungeon>();
  const [currentDaily, setCurrentDaily] =
    React.useState<GameStateCurrentDaily>();
  const [yesterdayDaily, setYesterdayDaily] =
    React.useState<GameStateYesterdayDaily>();

  const logoutUser = () => {
    window.localStorage.removeItem('kg-session');
    window.location.reload();
  };

  const setGameState = (result: GameState) => {
    setPlayer(result.player);
    setActiveGuild(result.activeGuild);
    setAvailableGuilds(result.availableGuilds);
    setActiveHeroes(result.activeHeroes);
    setAvailableHeroes(result.availableHeroes);
    setKgTokens(result.kggTokens);
    setGold(result.gold);
    setBeers(result.items.beers);
    setReviveScrolls(result.items.reviveScrolls);
    setBossTickets(result.items.bossTickets);
    setHeroFragments(result.items.heroFragments);
    setGuildFragments(result.items.guildFragments);
    setCurrentDungeon(result.currentDungeon);
    setCurrentDaily(result.currentDaily);
    setYesterdayDaily(result.yesterdayDaily);
  };

  function getPlayer() {
    // const result = await fetch('/api/v1/game-state/', {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // }).then((response) => response.json());

    const result: GameState = {
      gold: 1200,
      kggTokens: 50,
      player: {
        id: '123',
      },
      activeGuild: {
        id: '1',
        rarity: 'legendary',
        slots: 4,
      },
      availableGuilds: [
        {
          id: '2',
          rarity: 'rare',
          slots: 5,
        },
      ],
      activeHeroes: [
        {
          id: '1',
          code: '211001',
          needBeer: true,
          totalBeer: 8,
          type: 'champion',
          rarity: 'rare',
          class: 'melee',
        },
        {
          id: '2',
          code: '111001',
          needBeer: true,
          totalBeer: 5,
          type: 'hero',
          rarity: 'uncommon',
          class: 'melee',
        },
        {
          id: '3',
          code: '121001',
          needBeer: false,
          totalBeer: 3,
          type: 'hero',
          rarity: 'uncommon',
          class: 'melee',
        },
      ],
      availableHeroes: [
        {
          id: '4',
          code: '211001',
          totalBeer: 12,
          type: 'champion',
          rarity: 'rare',
          class: 'melee',
        },
        {
          id: '5',
          code: '112001',
          totalBeer: 2,
          type: 'hero',
          rarity: 'uncommon',
          class: 'melee',
        },
        {
          id: '6',
          code: '121001',
          totalBeer: 7,
          type: 'hero',
          rarity: 'common',
          class: 'archer',
        },
        {
          id: '7',
          code: '131001',
          totalBeer: 5,
          type: 'hero',
          rarity: 'mitic',
          class: 'mage',
        },
      ],
      items: {
        beers: 128,
        reviveScrolls: 23,
        bossTickets: 4,
        heroFragments: 83,
        guildFragments: 26,
      },
      currentDungeon: {
        status: 'done',
      },
      currentDaily: {
        status: 'done',
      },
      yesterdayDaily: {
        status: 'done',
      },
    };

    setGameState(result);

    setStatus('loaded');
    // if (token){
    //   cache[token] = result;
    // }
  }

  // React.useEffect(() => {
  //   const storedToken = window.localStorage.getItem('kg-session');
  //   const accessToken = storedToken
  //     ? JSON.parse(storedToken)?.access_token
  //     : false;
  //   if (accessToken) {
  //     setToken(accessToken);
  //   }
  // }, []);

  // React.useEffect(() => {
  //   if (!token) {
  //     return;
  //   }

  //   // use cached user data if we’ve already hit the API with this token
  //   if (cache[token]) {
  //     setGameState(cache[token]);
  //     setStatus('loaded');
  //     return;
  //   }

  //   getPlayer();
  // }, [token]);

  const state: any = {
    player,
    // token,
    status,
    logoutUser,
    getPlayer,
    kgTokens,
    gold,
    activeGuild,
    availableGuilds,
    activeHeroes,
    availableHeroes,
    beers,
    reviveScrolls,
    bossTickets,
    heroFragments,
    guildFragments,
    currentDungeon,
    currentDaily,
    yesterdayDaily,
    setGold,
  };

  return (
    <GameStateContext.Provider value={state}>
      {children}
    </GameStateContext.Provider>
  );
}

export function useGameState() {
  const state = React.useContext(GameStateContext);

  if (state === undefined) {
    throw new Error('useGameState must be used within a GameProvider');
  }

  return state;
}
