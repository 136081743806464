import { useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import Layout from '@/components/Layout';

const Login = () => {
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSuccessGoogleLogin = async (r) => {
    // console.log(r);
    await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/auth/google/login`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: r.tokenId }),
      },
    ).then((response) => response.json());

    // console.log(result);
  };

  const handleFailureGoogleLogin = (response) => {
    setErrorMessage(`Error: ${response}`);
    setOpenErrorDialog(true);
  };

  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
    setErrorMessage('');
  };

  return (
    <Layout title="Kingdom Guilds - Login">
      <Dialog
        open={openErrorDialog}
        onClose={handleErrorDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Login error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {process.env.REACT_APP_GOOGLE_CLIENT_ID && (
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="Sign in"
          scope="profile email openid"
          onSuccess={handleSuccessGoogleLogin}
          onFailure={handleFailureGoogleLogin}
          cookiePolicy="single_host_origin"
        />
      )}
    </Layout>
  );
};

export default Login;
