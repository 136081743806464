import { makeStyles } from '@mui/styles';
import { Helmet } from 'react-helmet-async';
import HomeSplashScreen from '@/components/HomeSplashScreen';
import TopBar from '@/components/Layout/topBar';
import Footer from '@/components/Layout/footer';

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
    backgroundColor: '#49da9c',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'hidden',
  },
}));

export default function Layout({
  children,
  title,
  showHome = false,
  showFooter = false,
}) {
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>
      <div className={classes.root} data-x={title}>
        <TopBar />
        {showHome && <HomeSplashScreen />}
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
      </div>
      {showFooter && <Footer />}
    </>
  );
}
