import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    appearance: 'button',
    backgroundColor: '#910909',
    border: 'solid transparent',
    borderRadius: '16px',
    borderWidth: '0 0 4px',
    boxSizing: 'border-box',
    color: '#FFF',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '36px',
    fontWeight: 700,
    letterSpacing: '.8px',
    lineHeight: '20px',
    margin: 0,
    outline: 'none',
    overflow: 'visible',
    padding: '25px 95px',
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    touchAction: 'manipulation',
    transform: 'translateZ(0)',
    transition: 'filter .2s',
    userSelect: 'none',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    '&:after': {
      backgroundClip: 'padding-box',
      backgroundColor: '#d10909',
      border: 'solid transparent',
      borderRadius: '16px',
      borderWidth: '0 0 4px',
      bottom: '-4px',
      content: '""',
      left: '0',
      position: 'absolute',
      right: '0',
      top: '0',
      zIndex: '-1',
    },
    '&:main': {
      userSelect: 'auto',
    },
    '&:focus': {
      userSelect: 'auto',
    },
    '&:hover:not(:disabled)': {
      filter: 'brightness(1.1)',
    },
    '&:disabled': {
      cursor: 'auto',
    },
  },
}));

export default function AewsomeButton({ children, to }) {
  const classes = useStyles();
  return (
    <Link className={classes.root} to={to} underline="none" color="#fff">
      {children}
    </Link>
  );
}
