import { useEffect } from 'react';
import { Box, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Layout from '@/components/Layout';
import HeroImage from '@/components/HeroImage';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  sections: {
    color: '#fff',
    margin: '20px auto',
    maxWidth: '90%',
    borderRadius: '5px',
    padding: theme.spacing(2),
    '& h2': {
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: 96,
      margin: '50px 0',
      color: '#000',
      textShadow:
        '4px 0 0 #fc0, -4px 0 0 #fc0, 0 4px 0 #fc0, 0 -4px 0 #fc0, 2px 2px #fc0, -2px -2px 0 #fc0, 2px -2px 0 #fc0, -2px 2px 0 #fc0',
      [theme.breakpoints.down('sm')]: {
        fontSize: 48,
        textShadow:
          '3px 0 0 #fc0, -3px 0 0 #fc0, 0 3px 0 #fc0, 0 -3px 0 #fc0, 1px 1px #fc0, -1px -1px 0 #fc0, 1px -1px 0 #fc0, -1px 1px 0 #fc0',
      },
    },
    '& h3': {
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: 48,
      margin: '20px 0 30px 0',
      textShadow:
        '3px 0 0 #000, -3px 0 0 #000, 0 3px 0 #000, 0 -3px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000',
      color: '#fc0',
      [theme.breakpoints.down('sm')]: {
        fontSize: 36,
        textShadow:
          '2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000',
      },
    },
    '& h4': {
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: 20,
      margin: '0 0 40px 0',
      textShadow:
        '1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000',
      color: '#fc0',
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
  },
  gamemode: {
    width: 300,
  },
  image: {
    width: '256px',
    height: '256px',
  },
}));

const Home = () => {
  const classes = useStyles();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Layout title="Kingdom Guilds - Home" showHome showFooter>
      <Box className={classes.sections} data-aos="fade-up">
        <h2>Gameplay</h2>
        <Stack direction="row" justifyContent="space-around" flexWrap="wrap">
          <Box className={classes.gamemode}>
            <h3>Dungeon</h3>
            <Typography fontSize={24} textAlign="justify">
              Players can send heroes to explore dungeons and find materials and
              treasures, this will be like put your heroes to farm
            </Typography>
          </Box>
          <Box className={classes.gamemode}>
            <h3>Story</h3>
            <Typography fontSize={24} textAlign="justify">
              Players can have a turn based battle on many levels with diferent
              difficulties and rewards, so you can think this like PvC
            </Typography>
          </Box>
          <Box className={classes.gamemode}>
            <h3>Arena</h3>
            <Typography fontSize={24} textAlign="justify">
              Players can enter on a season ranked arena to battle with other
              players from all the kingdoms, they will all compete to get on the
              top, where most valuable rewards awaits
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-around" flexWrap="wrap">
          <Box className={classes.gamemode}>
            <h3>Tools & Resources</h3>
            <Typography fontSize={24} textAlign="justify">
              Players can use their tools to get resources to further improve
              their own guild or sell them to other players
            </Typography>
          </Box>
          <Box className={classes.gamemode}>
            <h3>Craft & Forge</h3>
            <Typography fontSize={24} textAlign="justify">
              Well, you have many resources, many materials, many rare drops
              from dungeons or a rare mission, so this is where you will be
              using most of this things
            </Typography>
          </Box>
          <Box className={classes.gamemode}>
            <h3>Build</h3>
            <Typography fontSize={24} textAlign="justify">
              There will be many buildings on player`s guild, where each one
              will have a different utility, and the player can level up each
              building granting new benefits like enable a new recipe or a bonus
              on resource
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-around" flexWrap="wrap">
          <Box className={classes.gamemode}>
            <h3>Specialyze</h3>
            <Typography fontSize={24} textAlign="justify">
              Your heroes will start as one of following classes: warrior,
              archer or mage. Each one has two new sub-classes and all of them
              can be ascended to a more powerful version. This will impact on
              stats, available equipments, allowed skills and each class will
              have strenghts and weakness against others
            </Typography>
          </Box>
          <Box className={classes.gamemode}>
            <h3>Equipment</h3>
            <Typography fontSize={24} textAlign="justify">
              Players can forge weapons and armors, there will be many options,
              many recipes, a basic weapon that needs only few basic resources
              or a ultimate weapon that needs very rare ingredients
            </Typography>
          </Box>
          <Box className={classes.gamemode}>
            <h3>Skills</h3>
            <Typography fontSize={24} textAlign="justify">
              Players can use tomes on theirs academy to teach heroes new
              skills, these skills can change the course of the battle
            </Typography>
          </Box>
        </Stack>
        {/* <Stack direction="row" justifyContent="space-around">
          <Box className={classes.gamemode}>
            <h3>Missions</h3>
            <Typography fontSize={24} textAlign="justify">
              Among many buildings, there will be a tavern, where brave heroes
              find missions to complete and get rewards
            </Typography>
          </Box>
          <Box className={classes.gamemode}>
            <h3>Equipment</h3>
            <Typography fontSize={24} textAlign="justify">
              Players can forge weapons and armors, there will be many options,
              many recipes, a basic weapon that needs only few basic resources
              or a ultimate weapon that needs very rare ingredients
            </Typography>
          </Box>
          <Box className={classes.gamemode}>
            <h3>Skills</h3>
            <Typography fontSize={24} textAlign="justify">
              Players can use tomes on theirs academy to teach heroes new
              skills, these skills can change the course of the battle
            </Typography>
          </Box>
        </Stack> */}
      </Box>

      <Box className={classes.sections} data-aos="fade-up">
        <h2>NFTs</h2>
        <Stack direction="row" justifyContent="space-around" flexWrap="wrap">
          <Box className={classes.gamemode}>
            <h3>Heroes</h3>
            <h4>Players can have many heroes!</h4>
            <Typography fontSize={24} textAlign="justify">
              There is no guild without heroes, and the power of a guild is the
              power of its heroes. Player can earn heroes through gameplay,
              combine them to upgrade a hero, trade them on market, among other
              things.
            </Typography>
          </Box>
          <Box className={classes.gamemode}>
            <h3>Guilds</h3>
            <h4>Players will control a guild!</h4>
            <Typography fontSize={24} textAlign="justify">
              The rarity of the guild will dictate which dungeons and missions a
              player can do, among other things. So, a good guild is the most
              important aspect in the game, and of course a guild will be
              upgradable too.
            </Typography>
          </Box>
          <Box className={classes.gamemode}>
            <h3>Kingdom</h3>
            <h4>Players can rule a kingdom!</h4>
            <Typography fontSize={24} textAlign="justify">
              Will be the most wanted NFT on the game, and the mostly rare too,
              only a few will have! Do you have what it takes to rule the entire
              kingdom?
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-around" flexWrap="wrap">
          <Box className={classes.gamemode}>
            <h3>Tools</h3>
            <h4>Players will control a guild!</h4>
            <Typography fontSize={24} textAlign="justify">
              Tools will be used to get resources on farms, mines, forests and
              lakes. They will have rarity too, so there will be good and not so
              good tools that a player can craft or buy on market
            </Typography>
          </Box>
          <Box className={classes.gamemode}>
            <h3>Equipments</h3>
            <h4>If there are heroes, there are equipments too!</h4>
            <Typography fontSize={24} textAlign="justify">
              There are so many variables that each equipment will be unique,
              and multiple strategies can be made to win a battle
            </Typography>
          </Box>
          <Box className={classes.gamemode}>
            <h3>Tomes</h3>
            <h4>If there equips, why not skills?</h4>
            <Typography fontSize={24} textAlign="justify">
              Tomes will be items used to teach a hero a skill, the tome will be
              consumed, there is no turnback. Tomes will have rarity too, that
              will influence each skill on a different way, it could be more
              damage, or greater chance to activate or better boost
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-around" flexWrap="wrap">
          <Box className={classes.image}>
            <HeroImage code="100001" />
          </Box>
          <Box className={classes.image}>
            <HeroImage code="110001" />
          </Box>
          <Box className={classes.image}>
            <HeroImage code="120001" />
          </Box>
          <Box className={classes.image}>
            <HeroImage code="111001" />
          </Box>
          <Box className={classes.image}>
            <HeroImage code="121001" />
          </Box>
        </Stack>
      </Box>

      <Box className={classes.sections} data-aos="fade-up">
        <h2>Roadmap</h2>
        <Stack direction="row" justifyContent="space-around" flexWrap="wrap">
          <Box>
            <h3>Done</h3>
            <ul>
              <li>Website 1.0 release</li>
            </ul>
          </Box>
          <Box>
            <h3>Doing</h3>
            <ul>
              <li>Initial hero and guild offering</li>
              <li>Marketplace</li>
              <li>Dungeons</li>
              <li>Missions</li>
              <li>Buildings</li>
              <li>Site development</li>
              <li>NFTs rarity upgrade</li>
              <li>Resource tokens</li>
            </ul>
          </Box>
          <Box>
            <h3>Coming soon</h3>
            <ul>
              <li>KGT Token</li>
              <li>Game design revamp</li>
              <li>Website 2.0 release</li>
              <li>Mobile apps</li>
              <li>Story</li>
              <li>Arena</li>
            </ul>
          </Box>
        </Stack>
      </Box>
    </Layout>
  );
};

export default Home;
