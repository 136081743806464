import { AppBar, Container, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'center',
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <AppBar position="static" color="primary">
      <Container maxWidth="md">
        <Toolbar className={classes.root}>
          <Typography variant="body1" color="inherit" alignSelf="center">
            © 2021 Kingdom Guilds
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
