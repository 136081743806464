import { useEffect, useState } from 'react';
import { Stack, Box, SvgIcon, Button } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import Layout from '@/components/Layout';
import GuildIcon from '@/components/GuildIcon';
import HeroSlot from '@/components/HeroSlot';
import { useGameState } from '@/context/game';
import GameButton from '@/components/GameButton';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: '#000000dd',
      height: 'calc(100vh - 64px)',
      '& h3': {
        color: '#fff',
      },
      overflowY: 'auto',
    },
    wrapper: {
      padding: '20px',
    },
    heroBox: {
      width: 300,
      background: '#000000cc',
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '5px',
    },
    heroImage: {
      width: 200,
    },
    guildIcon: {
      textAlign: 'center',
      width: '50px',
      height: '50px',
    },
  }),
);

const PlayGuild = () => {
  const classes = useStyles();
  const {
    player,
    kgTokens,
    gold,
    activeGuild,
    availableGuilds,
    activeHeroes,
    availableHeroes,
    beers,
    reviveScrolls,
    bossTickets,
    heroFragments,
    guildFragments,
    // currentDungeon,
    // currentDaily,
    // yesterdayDaily,
    getPlayer,
  }: any = useGameState();

  const [guildColor, setGuildColor] = useState('#fff');
  // const [champion, setChampion] = useState();
  const guildColors = {
    free: '#ccc',
    common: '#fff',
    uncommon: '#0f0',
    rare: '#f00',
    mitic: '#f0f',
    legendary: '#ff0',
  };

  const ENABLED = false;

  useEffect(() => {
    if (player?.id) {
      return;
    }
    getPlayer();
  }, [player]);

  useEffect(() => {
    if (!activeGuild?.rarity) {
      return;
    }
    setGuildColor(guildColors[activeGuild.rarity]);
  }, [activeGuild]);

  // useEffect(() => {
  //   if (activeHeroes?.length == 0){
  //     return
  //   }
  //   activeHeroes.forEach(hero => {
  //     if (hero.type == "champion") {
  //       setChampion(hero)
  //       return
  //     }
  //   });
  // }, [activeHeroes]);
  if (!ENABLED) {
    return (
      <Layout title="Kingdom Guilds - Play">
        <Box>Soon...</Box>
      </Layout>
    );
  }
  return (
    <Layout title="Kingdom Guilds - Play">
      <Box className={classes.root}>
        <Box className={classes.wrapper}>
          <Stack direction="row" justifyContent="space-between" color="#fff">
            <Box>Goldc: {gold}</Box>
            <Box>Beers: {beers}</Box>
            <Box>Revive scrolls: {reviveScrolls}</Box>
            <Box>Hero Fragments: {heroFragments}</Box>
            <Box>Guild Fragments: {guildFragments}</Box>
            <Box>Boss tickets: {bossTickets}</Box>
            <Box>$KG: {kgTokens}</Box>
          </Stack>
          <Box>
            <GameButton>Teste</GameButton>
            <SvgIcon className={classes.guildIcon}>
              <GuildIcon fill={guildColor} />
            </SvgIcon>
          </Box>
          <Box>
            <h3>
              Active Heroes ({activeHeroes?.length}/{activeGuild?.slots})
            </h3>
            <Button>Dungeon</Button>
            <Stack
              direction="row"
              justifyContent="space-around"
              flexWrap="wrap"
            >
              {activeHeroes?.map((hero) => (
                <HeroSlot hero={hero} isActive key={hero.id} />
              ))}
            </Stack>
          </Box>
          <h3>Available guilds ({availableGuilds?.length})</h3>
          <h3>Available heroes ({availableHeroes?.length})</h3>
          <Stack direction="row" justifyContent="space-around" flexWrap="wrap">
            {availableHeroes?.map((hero) => (
              <HeroSlot hero={hero} key={hero.id} />
            ))}
          </Stack>
        </Box>
      </Box>
    </Layout>
  );
};

export default PlayGuild;
