import { makeStyles } from '@mui/styles';
import { Box, Stack, Tooltip, IconButton, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import classNames from 'classnames';

import SportsBarIcon from '@mui/icons-material/SportsBar';
import InfoIcon from '@mui/icons-material/Info';
import HeroImage from '../HeroImage';

const useStyles = makeStyles(() => ({
  root: {
    width: 360,
    height: 220,
    background: '#000000cc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 5,
    border: '4px outset #fff',
    margin: 20,
    padding: 10,
  },
  hero: {},
  champion: {},
  melee: {},
  ranged: {},
  mage: {},
  common: {},
  uncommon: {
    borderColor: '#0f0',
  },
  rare: {
    borderColor: '#00f',
  },
  mitic: {
    borderColor: '#f0f',
  },
  legendary: {
    borderColor: '#ff0',
  },
  heroImage: {
    width: 200,
    '$champion > &': {
      width: 280,
    },
  },
}));

export default function HeroSlot({ hero, isActive = false }): JSX.Element {
  /* TCRXXX */

  const classes = useStyles();

  const heroBoxClasses = classNames(
    classes.root,
    classes[hero.type],
    classes[hero.class],
    classes[hero.rarity],
  );

  return (
    <Box className={heroBoxClasses}>
      <Box className={classes.heroImage}>
        <HeroImage code={hero.code} />
      </Box>
      <Stack direction="column" justifyContent="space-around">
        <Tooltip title="Info">
          <IconButton>
            <InfoIcon htmlColor="#00f" />
          </IconButton>
        </Tooltip>
        {isActive && (
          <>
            <Tooltip title="Drink">
              <Stack direction="row" alignItems="center">
                <IconButton>
                  <SportsBarIcon htmlColor="#ff0" />
                </IconButton>
                <Typography color={hero.needBeer ? '#f00' : '#ff0'}>
                  {`${hero.needBeer ? 0 : hero.totalBeer}/${hero.totalBeer}`}
                </Typography>
              </Stack>
            </Tooltip>
            <Tooltip title="Remove">
              <IconButton>
                <RemoveCircleIcon htmlColor="#f00" />
              </IconButton>
            </Tooltip>
          </>
        )}
        {!isActive && (
          <>
            <Tooltip title="Drink">
              <Stack direction="row" alignItems="center">
                <IconButton>
                  <SportsBarIcon htmlColor="#ff0" />
                </IconButton>
                <Typography color="#ff0">{hero.totalBeer}</Typography>
              </Stack>
            </Tooltip>
            <Tooltip title="Add">
              <IconButton>
                <AddCircleIcon htmlColor="#0f0" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Stack>
    </Box>
  );
}
