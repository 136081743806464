import { makeStyles } from '@mui/styles';

const wnn001 = require('@/images/heroes/100001.png').default;
const wkn001 = require('@/images/heroes/110001.png').default;
const wka001 = require('@/images/heroes/111001.png').default;
const wbn001 = require('@/images/heroes/120001.png').default;
const wba001 = require('@/images/heroes/121001.png').default;

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: '1600%',
    animation: '$sprite 1.2s steps(15) infinite',
  },
  parent: {
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '100%',
    },
  },
  '@keyframes sprite': {
    from: {
      backgroundPositionX: 0,
    },
    to: {
      backgroundPositionX: '-100%',
    },
  },
}));

const images = {
  '100001': wnn001,
  '110001': wkn001,
  '111001': wka001,
  '120001': wbn001,
  '121001': wba001,
};

export default function HeroImage({ code }): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.parent}>
      <div
        className={classes.root}
        style={{
          color: '#f00',
          backgroundImage: `url(${images[code]})`,
        }}
      />
    </div>
  );
}
