import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#212121',
      light: '#484848',
      dark: '#000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f9a825',
      light: '#ffd95a',
      dark: '#c17900',
      contrastText: '#000',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
