import { StylesProvider, createGenerateClassName } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material';
import { GameProvider } from '@/context/game';
import PlayGuild from '@/views/PlayGuild';
import Home from '@/views/Home';
import Market from '@/views/Market';
import Sales from '@/views/Sales';
import Faq from '@/views/Faq';
import createEmotionCache from '@/createEmotionCache';
import theme from '@/theme';
import Login from './views/Login';

const clientSideEmotionCache = createEmotionCache();

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});

function App(props) {
  const { emotionCache = clientSideEmotionCache } = props;

  return (
    <CacheProvider value={emotionCache}>
      <StylesProvider generateClassName={generateClassName}>
        <GameProvider>
          <CssBaseline />
          <HelmetProvider>
            <ThemeProvider theme={theme}>
              <div className="App">
                <BrowserRouter>
                  <Switch>
                    <Route path="/faq">
                      <Faq />
                    </Route>
                    <Route path="/sales">
                      <Sales />
                    </Route>
                    <Route path="/market">
                      <Market />
                    </Route>
                    <Route path="/play/guild">
                      <PlayGuild />
                    </Route>
                    <Route path="/login">
                      <Login />
                    </Route>
                    <Route path="/">
                      <Home />
                    </Route>
                  </Switch>
                </BrowserRouter>
              </div>
            </ThemeProvider>
          </HelmetProvider>
        </GameProvider>
      </StylesProvider>
    </CacheProvider>
  );
}

export default App;
