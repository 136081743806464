import React from 'react';
import {
  Theme,
  AppBar,
  Toolbar,
  Box,
  Divider,
  Stack,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AccountCircle } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, Link } from 'react-router-dom';

const discordImage = require('@/images/discord.png').default;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#000',
  },
  logoBox: {
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      left: -20,
      position: 'relative',
    },
  },
  logo: {
    cursor: 'pointer',
    height: 32,
    marginLeft: theme.spacing(3),
  },
  title: {
    marginLeft: theme.spacing(1),
    color: '#fff',
  },
  discord: {
    backgroundColor: '#7289da',
    backgroundImage: `url(${discordImage})`,
    backgroundSize: '100px 20px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    fontSize: 0,
    display: 'inline-block',
    marginLeft: 10,
    width: '130px',
    height: '30px',
    '&:hover': {
      backgroundColor: '#2835C2',
    },
  },
  navlink: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  },
}));

const logoImage = require('@/images/logo.png').default;

export default function TopBar() {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window.document.body;

  const drawer = (
    <List>
      <ListItem button key="home" component={Link} to="/">
        <ListItemText>Home</ListItemText>
      </ListItem>
      <ListItem button key="play" component={Link} to="/play/guild">
        <ListItemText>Play</ListItemText>
      </ListItem>
      <ListItem button key="market" component={Link} to="/market">
        <ListItemText>Market</ListItemText>
      </ListItem>
      <ListItem button key="sales" component={Link} to="/sales">
        <ListItemText>Sales</ListItemText>
      </ListItem>
      <ListItem button key="faq" component={Link} to="/faq">
        <ListItemText>FAQ</ListItemText>
      </ListItem>
      <ListItem button key="login">
        <ListItemText>Login</ListItemText>
      </ListItem>
    </List>
  );

  return (
    <>
      <AppBar className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="secondary"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex" alignItems="center" className={classes.logoBox}>
            <img
              className={classes.logo}
              src={logoImage}
              alt="logo"
              width="32"
              height="32"
            />
            <Box className={classes.title}>Kingdom Guilds</Box>
          </Box>
          <Stack
            direction="row"
            spacing={2}
            divider={<Divider orientation="vertical" flexItem />}
            sx={{
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            <NavLink className={classes.navlink} to="/">
              Home
            </NavLink>
            <NavLink className={classes.navlink} to="/play/guild">
              Play
            </NavLink>
            <NavLink className={classes.navlink} to="/market">
              Market
            </NavLink>
            <NavLink className={classes.navlink} to="/sales">
              Sales
            </NavLink>
            <NavLink className={classes.navlink} to="/faq">
              FAQ
            </NavLink>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            divider={<Divider orientation="vertical" flexItem />}
            width={300}
            sx={{
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            <a className={classes.discord} href="https://discord.gg/uFTAnwkbzS">
              &nbsp;
            </a>
            <Button
              color="secondary"
              component="a"
              variant="outlined"
              startIcon={<AccountCircle />}
            >
              Login
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
}
